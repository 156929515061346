<template>
  <div v-click-outside="clickOutside" class="mr-12 ml-6 relative w-48">
    <p class="bg-white px-4 py-2 rounded-xl cursor-pointer font-semibold" @click="changeStatus = true">
      {{ statusFilter }}
    </p>
    <div v-show="changeStatus" class="absolute w-full bg-white p-4 rounded-xl mt-4 border-gray-300 border shadow-md">
      <p class="py-2 border-b border-gray-300 cursor-pointer" @click="updateStatus($t('filter.status.1'))">
        {{ $t("filter.status.1") }}
      </p>
      <p class="py-2 border-b border-gray-300 cursor-pointer" @click="updateStatus($t('filter.status.2'))">
        {{ $t("filter.status.2") }}
      </p>
      <p class="py-2 border-b border-gray-300 cursor-pointer" @click="updateStatus($t('filter.status.3'))">
        {{ $t("filter.status.3") }}
      </p>
      <p class="py-2 border-b border-gray-300 cursor-pointer" @click="updateStatus($t('filter.status.4'))">
        {{ $t("filter.status.4") }}
      </p>
      <p class="pt-2 cursor-pointer" @click="updateStatus($t('filter.status.5'))">
        {{ $t("filter.status.5") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusFilter",
  data() {
    return {
      statusFilter: this.$t("filter.status.1"),
      changeStatus: false
    };
  },

  watch: {
    statusFilter() {
      this.$emit("status-changed", this.statusFilter);
    }
  },

  methods: {
    updateStatus(status) {
      this.statusFilter = status;
      this.changeStatus = false;
    },

    clickOutside() {
      if (this.changeStatus) {
        this.changeStatus = false;
      }
    }
  }
};
</script>

<style scoped></style>
