<template>
  <div class="py-12 px-24">
    <h2 class="text-xl font-semibold mb-4">
      {{ $t("availability.title") }}
    </h2>
    <ul class="list-disc ml-5">
      <li class="text-sm text-inactiveText">
        {{ $t("availability.subtitle.1") }}
      </li>
      <li class="text-sm text-inactiveText">
        {{ $t("availability.subtitle.2") }}
      </li>
      <li class="text-sm text-inactiveText">
        {{ $t("availability.subtitle.3") }}
      </li>
      <li class="text-sm text-inactiveText">
        {{ $t("availability.subtitle.4") }}
      </li>
    </ul>

    <div v-for="index in 8" :key="'week_' + index" class="mt-6 p-4 bg-neutralBackground4 rounded-xl">
      <div class="flex justify-between items-start cursor-pointer" @click="activeWeekIndex = index">
        <h4 class="text-base font-semibold">
          <span class="mr-6"
            >{{ $t("week.generic", { count: getCWIndex(index) }) }}
            <span v-if="index === 1">({{ $t("current.generic") }})</span></span
          >
          {{ weekValue(index) }}
        </h4>
        <svg
          :class="{ 'transform rotate-90 text-primaryBackground': activeWeekIndex === index }"
          style="width: 24px; height: 24px"
          viewBox="0 0 24 24"
        >
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" fill="currentColor" />
        </svg>
      </div>
      <div v-if="activeWeekIndex === index" class="pl-8">
        <div v-for="(day, i) in allDaysList[activeWeekIndex]" :key="day.format('ddd, DD MMM')">
          <div v-if="showDay(day)" class="w-full pb-16">
            <div class="my-4 flex justify-start items-center">
              <p class="mr-6">{{ day.format("ddd, DD MMM") }}</p>

              <!--            <p class="text-sm text-primaryBackground cursor-pointer">+ Add new time range</p>-->
            </div>
            <div class="mb-4">
              <VueTimepicker
                v-model="selectedHour[i]"
                :hour-range="[[6, 22]]"
                :minute-interval="15"
                :minute-range="selectedHour[i].HH === '22' ? ['00'] : ['00', '15', '30', '45']"
                auto-scroll
                close-on-complete
                input-class="rounded-md outline-none custom-border"
              >
              </VueTimepicker>

              <primary-button
                :disabled="selectedHour[i].HH === '' || selectedHour[i].mm === ''"
                class="ml-4"
                @click="createTodaySlot(day, i)"
              >
                {{ $t("create.generic") }}
              </primary-button>
            </div>
            <p class="text-sm font-semibold my-4">{{ $t("created.slots") }}</p>

            <div v-if="slotsInCurrentDay(day)" class="flex justify-start items-center flex-wrap">
              <div v-for="slot in dateKeys" :key="'hour_' + slot">
                <!-- todo accepted bg-->
                <AvailabilitySlot
                  v-if="isSlotInCurrentDay(slot, day)"
                  :accepted-slot="isSlotAccepted(slot)"
                  :availability-slot="slot"
                  @remove-slot="removeSlot"
                >
                  {{ getSlotTime(slot) }}
                </AvailabilitySlot>
              </div>
            </div>
            <div v-else>
              <p class="font-semibold text-sm text-inactiveText">{{ $t("created.slots.subtitle") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import AvailabilitySlot from "@/components/availability/AvailabilitySlot";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(isoWeek);

dayjs.extend(utc);

export default {
  name: "Availability",
  components: { AvailabilitySlot, PrimaryButton, VueTimepicker },
  data() {
    return {
      activeWeekIndex: "today",
      allDaysList: {},
      dateKeys: [],
      todayHour: {
        HH: "",
        mm: ""
      },
      selectedHour: [],
      selectedSessions: []
    };
  },

  computed: {
    ...mapState("sessions", ["sessions"]),

    today() {
      return dayjs().format("ddd, DD MMM");
    },

    currentHour() {
      return dayjs().hour() + 1;
    },

    todayHours() {
      const start = dayjs().hour() + 1;

      const hours = [];

      for (let i = start; i < 24; i++) {
        hours.push(`${i}:00`);
      }

      return hours;
    }
  },

  watch: {
    async activeWeekIndex() {
      this.selectedHour = [];
      this.selectedSessions = this.sessions[this.activeWeekIndex];

      if (this.activeWeekIndex > 1) {
        this.selectedSessions.push(...this.sessions[this.activeWeekIndex - 1]);
      }

      for (let i = 0; i < 8; i++) {
        this.selectedHour.push({
          HH: "",
          mm: ""
        });
      }
      await this.getSessionList();
    }
  },

  methods: {
    getCWIndex(index) {
      return this.allDaysList[index][0].isoWeek();
    },
    async getSessionList() {
      await this.$store.dispatch("sessions/getSessions");
      this.mapDateKeys();
    },
    mapDateKeys() {
      this.dateKeys = [];
      this.sessions[this.activeWeekIndex].forEach((s) => {
        this.dateKeys.push(dayjs(s.start).format("YYYY-MM-DD HH:mm"));
      });
      if (this.activeWeekIndex > 1 && this.activeWeekIndex !== "today") {
        this.sessions[this.activeWeekIndex - 1].forEach((s) => {
          this.dateKeys.push(dayjs(s.start).format("YYYY-MM-DD HH:mm"));
        });
      }
    },

    isSlotInCurrentDay(slot, day) {
      return dayjs(slot).format("YYYY-MM-DD") === dayjs(day).format("YYYY-MM-DD");
    },

    slotsInCurrentDay(day) {
      for (let i = 0; i < this.dateKeys.length; i++) {
        const date = this.dateKeys[i];
        if (dayjs(date).format("YYYY-MM-DD") === dayjs(day).format("YYYY-MM-DD")) {
          return true;
        }
      }
      return false;
    },

    weekValue(index) {
      return (
        this.allDaysList[index + ""][0].format("DD.MM.YYYY") +
        " - " +
        this.allDaysList[index + ""][6].format("DD.MM.YYYY")
      );
    },

    getHour(hour) {
      return hour === 0 ? "00:00" : hour < 10 ? "0" + hour + ":00" : hour + ":00";
    },

    getSlotTime(slot) {
      return dayjs(slot).format("HH:mm");
    },

    isActive(day, hour) {
      day.$H = hour;
      day.$m = 0;
      return this.dateKeys.indexOf(day.format("YYYY-MM-DD HH:00")) !== -1;
    },

    showDay(day) {
      return day > dayjs().add(2, "day");
    },

    isSlotAccepted(slot) {
      for (let i = 0; i < this.selectedSessions.length; i++) {
        const s = this.selectedSessions[i];

        if (dayjs(s.start).format("YYYY-MM-DD HH:mm:00") === dayjs(slot).format("YYYY-MM-DD HH:mm:00")) {
          if (s.status === "accepted") {
            return true;
          }
        }
      }
      return false;
    },

    isActiveToday(h) {
      return this.dateKeys.indexOf(dayjs().format("YYYY-MM-DD ") + h) !== -1;
    },

    async createTodaySlot(day, i) {
      if (!day) {
        const dateUTC = dayjs(dayjs().format("YYYY-MM-DD " + this.todayHour.HH + ":" + this.todayHour.mm)).utc();
        await this.$store.dispatch("sessions/createSessions", {
          sessions: [
            {
              start: dateUTC.format("YYYY-MM-DD HH:mm:00"),
              end: dateUTC.add(45, "minute").format("YYYY-MM-DD HH:mm:00")
            }
          ]
        });
        this.todayHour = {
          HH: "",
          mm: ""
        };
      } else {
        const dateUTC = dayjs(
          dayjs(day).format("YYYY-MM-DD " + this.selectedHour[i].HH + ":" + this.selectedHour[i].mm)
        ).utc();
        await this.$store.dispatch("sessions/createSessions", {
          start: dateUTC.format("YYYY-MM-DD HH:mm:00"),
          end: dateUTC.add(45, "minute").format("YYYY-MM-DD HH:mm:00")
        });
        this.selectedHour[i] = { HH: "", mm: "" };
      }

      this.mapDateKeys();
      this.selectedSessions = this.sessions[this.activeWeekIndex];

      if (this.activeWeekIndex > 1) {
        this.selectedSessions.push(...this.sessions[this.activeWeekIndex - 1]);
      }
    },

    async removeSlot(slot) {
      const date = dayjs(slot);

      for (let i = 0; i < this.selectedSessions.length; i++) {
        if (dayjs(this.selectedSessions[i].start).format("YYYY-MM-DD HH:00") === date.format("YYYY-MM-DD HH:00")) {
          if (this.selectedSessions[i].status === "free") {
            await this.$store.dispatch("sessions/deleteSession", this.selectedSessions[i].id);
            await this.mapDateKeys();
            this.selectedSessions = this.sessions[this.activeWeekIndex];

            if (this.activeWeekIndex > 1) {
              this.selectedSessions.push(...this.sessions[this.activeWeekIndex - 1]);
            }
            return;
          }
        }
      }
    }
  },

  async created() {
    for (let i = 0; i < 8; i++) {
      this.selectedHour.push({
        HH: "",
        mm: ""
      });
    }

    const currentIsoWeek = dayjs().isoWeek();
    const lastIsoWeek = currentIsoWeek + 7;

    let index = 0;

    for (let week = currentIsoWeek; week <= lastIsoWeek; week++) {
      index++;
      const filledWeek = [];
      for (let day = 0; day < 7; day++) {
        filledWeek.push(dayjs().isoWeek(week).startOf("isoWeek").add(day, "day"));
      }

      this.allDaysList[index] = filledWeek;
    }

    await this.getSessionList();
  }
};
</script>

<style scoped>
.custom-border {
  border-color: #f0b71a !important;
}

.active {
  background-color: #f0b71a !important;
}
</style>
