<template>
  <div class="w-full flex flex-col justify-center items-center">
    <div class="w-full flex justify-between items-center mb-8">
      <title-text>{{ $t("reset.password.title") }}</title-text>
      <caption-text>
        <router-link :to="{ name: 'SignIn' }" class="text-primaryBackground">
          {{ $t("remember.password") }}
        </router-link>
      </caption-text>
    </div>
    <main-input
      :errors="passwordErrors"
      class="w-full mb-4"
      password
      @change-to-text="passwordType = 'text'"
      @change-to-password="passwordType = 'password'"
    >
      <input
        v-model="password"
        :placeholder="$t('password.generic')"
        :type="passwordType"
        class="w-full"
        @blur="$v.password.$touch()"
      />
    </main-input>
    <main-input
      :errors="passwordConfirmationErrors"
      class="w-full mb-4"
      password
      @change-to-text="passwordConfirmationType = 'text'"
      @change-to-password="passwordConfirmationType = 'password'"
    >
      <input
        v-model="passwordConfirmation"
        :placeholder="$t('password.confirmation')"
        :type="passwordConfirmationType"
        class="w-full"
        @blur="$v.passwordConfirmation.$touch()"
      />
    </main-input>

    <primary-button :disabled="$v.$anyError" @click="resetPassword">
      {{ $t("submit.generic") }}
    </primary-button>
  </div>
</template>

<script>
import TitleText from "@/components/ui-kit/typography/TitleText";
import CaptionText from "@/components/ui-kit/typography/CaptionText";
import MainInput from "@/components/ui-kit/form/MainInput";
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import AuthClient from "@/services/authClient";
import { maxLength, minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ResetPassword",
  components: { PrimaryButton, MainInput, CaptionText, TitleText },
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      passwordType: "password",
      passwordConfirmationType: "password"
    };
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("password.required"));
      !this.$v.password.minLength && errors.push(this.$t("password.longer", { count: "9" }));
      !this.$v.password.maxLength && errors.push(this.$t("password.shorter", { count: "31" }));
      const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
      if (!strongRegex.test(this.password)) {
        errors.push(this.$t("password.contain"));
      }
      return errors;
    },
    passwordConfirmationErrors() {
      const errors = [];
      if (!this.$v.passwordConfirmation.$dirty) return errors;
      !this.$v.passwordConfirmation.required && errors.push(this.$t("password.confirmation.required"));
      !this.$v.passwordConfirmation.sameAsPassword && errors.push(this.$t("password.confirmation.equal"));
      return errors;
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(30)
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs("password")
    }
  },
  methods: {
    async resetPassword() {
      this.$v.$touch();
      if (this.$v.$invalid || this.loading) {
        return;
      }
      await AuthClient.resetPassword({
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: this.password
      });
      await this.$router.push({ name: "SignIn" });
    }
  }
};
</script>

<style scoped></style>
