<template>
  <button
    :class="sm ? 'px-3 py-1 text-sm font-normal' : 'px-6 py-2 font-bold'"
    :disabled="disabled"
    class="rounded-30 bg-neutralBackground2 outline-none"
    @click.prevent="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "SecondaryButton",
  props: {
    disabled: Boolean,
    sm: Boolean
  }
};
</script>

<style scoped>
button:active {
  @apply bg-neutralBackground2 bg-opacity-75;
}

button:focus {
  outline: none;
}

button:disabled {
  @apply bg-neutralBackground3;
}
</style>
