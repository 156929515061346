export default {
  getToken() {
    return localStorage.getItem("coach-app-token");
  },

  setToken(token) {
    localStorage.setItem("coach-app-token", token);
  },
  removeToken() {
    localStorage.removeItem("coach-app-token");
  },
  setLanguage(lang) {
    localStorage.setItem("humanoo-coach-language", lang);
  },

  getLanguage() {
    return localStorage.getItem("humanoo-coach-language");
  }
};
