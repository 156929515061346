<template>
  <div ref="observe" class="text-primaryBackground text-center">{{ $t("loading.more") }}</div>
</template>

<script>
export default {
  name: "ScrollObserver",
  props: {
    showStatus: Boolean
  },

  data() {
    return {
      observer: null
    };
  },

  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    });

    this.observer.observe(this.$refs.observe);
  }
};
</script>
