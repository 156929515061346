<template>
  <div class="w-full flex flex-col justify-center items-center">
    <div class="w-full flex justify-between items-center">
      <title-text>{{ $t("sign.in.trouble") }}</title-text>
      <caption-text>
        <router-link :to="{ name: 'SignIn' }" class="text-primaryBackground">
          {{ $t("remember.password") }}
        </router-link>
      </caption-text>
    </div>
    <caption-text class="text-inactiveText my-3">
      {{ $t("email.reset.password") }}
    </caption-text>
    <main-input :errors="emailErrors" class="w-full mb-8">
      <input v-model="email" :placeholder="$t('email.generic')" class="w-full" type="email" @blur="$v.email.$touch()" />
    </main-input>

    <primary-button :disabled="$v.$anyError" @click="forgotPassword">
      {{ $t("submit.generic") }}
    </primary-button>
  </div>
</template>

<script>
import TitleText from "@/components/ui-kit/typography/TitleText";
import CaptionText from "@/components/ui-kit/typography/CaptionText";
import MainInput from "@/components/ui-kit/form/MainInput";
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import { email, required } from "vuelidate/lib/validators";
import authClient from "@/services/authClient";

export default {
  name: "ForgotPassword",
  components: { PrimaryButton, MainInput, CaptionText, TitleText },
  data() {
    return {
      email: ""
    };
  },
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push(this.$t("email.required"));
      !this.$v.email.email && errors.push(this.$t("email.valid"));
      return errors;
    }
  },

  methods: {
    async forgotPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      await authClient.forgotPassword({ email: this.email });
      this.$notify({
        group: "notifications",
        title: this.$t("email.sent.title"),
        text: this.$t("email.sent.subtitle"),
        type: "success"
      });
    }
  }
};
</script>

<style scoped></style>
