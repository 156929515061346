import SessionsClient from "@/services/sessionsClient";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";

dayjs.extend(isToday);

export const namespaced = true;

export const state = {
  appointments: [],
  acceptedAppointments: [],
  canceledAppointments: [],
  sessions: [],
  videoRoom: null,
  activeAppointment: null,
  pastAppointments: [],
  pastAppointmentsPagination: {
    current: 1,
    next: 1
  },
  lastSession: null
};

export const mutations = {
  SET_APPOINTMENTS(state, appointments) {
    state.appointments = appointments;
  },
  SET_ACCEPTED_APPOINTMENTS(state, appointments) {
    state.acceptedAppointments = appointments;
  },

  SET_CANCELED_APPOINTMENTS(state, appointments) {
    state.canceledAppointments = appointments;
  },

  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
  },

  SET_VIDEO_ROOM(state, room) {
    state.videoRoom = room;
  },

  SET_ACTIVE_APPOINTMENT(state, appointment) {
    state.activeAppointment = appointment;
  },

  SET_PAST_APPOINTMENTS(state, appointments) {
    state.pastAppointments.push(...appointments);
  },

  SET_PAST_APPOINTMENTS_PAGINATION(state, pagination) {
    state.pastAppointmentsPagination.current = pagination.current_page;
    state.pastAppointmentsPagination.next =
      pagination.current_page !== pagination.last_page ? pagination.current_page + 1 : null;
  },

  REFRESH_PAST_PAGES(state) {
    state.pastAppointmentsPagination.next = 1;
    state.pastAppointmentsPagination.current = 1;
  },

  REFRESH_PAST_APPOINTMENTS(state) {
    state.pastAppointments = [];
  },

  SET_LAST_SESSION(state, session) {
    state.lastSession = session;
  }
};

export const actions = {
  async getAppointments({ commit }) {
    const { data } = await SessionsClient.getAppointments();
    const accepted = [];
    const canceled = [];
    data.forEach((a) => {
      if (dayjs(a.start) >= dayjs().subtract(1, "hour")) {
        if (a.status === "accepted") {
          accepted.push(a);
        } else {
          canceled.push(a);
        }
      }
    });

    commit("SET_APPOINTMENTS", data);
    commit("SET_ACCEPTED_APPOINTMENTS", accepted);
    commit("SET_CANCELED_APPOINTMENTS", canceled);
  },

  async getAcceptedAppointments({ commit }) {
    const { data } = await SessionsClient.getAcceptedAppointments();
    commit("SET_ACCEPTED_APPOINTMENTS", data.data);
  },
  async getCanceledAppointments({ commit }) {
    const { data } = await SessionsClient.getCanceledAppointments();
    commit("SET_CANCELED_APPOINTMENTS", data.data);
  },
  async getPastAppointments({ commit, state }, { status, start, end }) {
    if (!state.pastAppointmentsPagination.next) {
      return;
    }
    const { data } = await SessionsClient.getPastAppointments(
      state.pastAppointmentsPagination.next,
      status,
      start,
      end
    );

    commit("SET_PAST_APPOINTMENTS", data.data);
    await commit("SET_PAST_APPOINTMENTS_PAGINATION", data.meta);
  },
  async getLastSession({ commit }) {
    const { data } = await SessionsClient.getPastAppointments(1, "accepted", "", "");
    commit("SET_LAST_SESSION", data.data[0]);
  },
  async refreshPastAppointments({ commit }) {
    await commit("REFRESH_PAST_PAGES");
    await commit("REFRESH_PAST_APPOINTMENTS");
  },
  async cancelAppointment({ dispatch }, params) {
    await SessionsClient.cancelAppointment(params);
    dispatch("getAppointments");
  },
  async getSessions({ commit }) {
    const { data } = await SessionsClient.getSessions();
    const filteredSessions = {
      today: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: []
    };
    data.forEach((session) => {
      let week;
      if (dayjs(session.start).isToday()) {
        filteredSessions["today"].push(session);
      } else {
        week = Math.floor(dayjs(session.start).diff(dayjs(), "day") / 7);
        if (filteredSessions[week + 1]) {
          filteredSessions[week + 1].push(session);
        }
      }
    });
    commit("SET_SESSIONS", filteredSessions);
  },
  async createSessions({ dispatch }, sessions) {
    await SessionsClient.createSessions(sessions);
    await dispatch("getSessions");
  },
  async deleteSession({ dispatch }, id) {
    await SessionsClient.deleteSession(id);
    await dispatch("getSessions");
  },
  async getTwilioVideoRoom({ commit }, id) {
    const { data } = await SessionsClient.getTwilioVideoRoom(id);
    const activeAppointment = await SessionsClient.getAppointment(id);
    commit("SET_VIDEO_ROOM", data);
    commit("SET_ACTIVE_APPOINTMENT", activeAppointment.data);
  },
  async getAllSessions({ commit }) {
    const { data } = await SessionsClient.getSessions();
    commit("SET_ALL_SESSIONS", data);
  }
};
