<template>
  <caption-text class="text-inactiveText"> ©{{ year }} {{ $t("humanoo.rights") }}</caption-text>
</template>

<script>
import CaptionText from "@/components/ui-kit/typography/CaptionText";
import dayjs from "dayjs";

export default {
  name: "Copyright",
  components: { CaptionText },
  data() {
    return {
      year: dayjs().format("YYYY")
    };
  }
};
</script>

<style scoped></style>
