<template>
  <div class="py-12 px-24">
    <modal :adaptive="true" :width="420" height="auto" name="sessionPreview" @before-close="closeModal">
      <div class="py-4 px-8">
        <h3 class="text-center font-bold mb-6 text-lg">{{ $t("session.preview.title") }}</h3>
        <div class="relative">
          <div v-show="!isVideoOn" class="video-bg video-overlay">{{ $t("video.disabled") }}</div>
          <video ref="testVideo" autoplay class="object-cover rounded-md" muted></video>

          <div class="controls flex">
            <div class="py-4 px-6 cursor-pointer" @click="isAudioOn = !isAudioOn">
              <img v-if="isAudioOn" alt="on" class="h-8 w-8" src="../assets/images/video/micro-on.svg" />
              <img v-else alt="on" class="h-8 w-8" src="../assets/images/video/micro-off.svg" />
            </div>
            <div class="py-4 px-6 cursor-pointer" @click="isVideoOn = !isVideoOn">
              <img v-if="isVideoOn" alt="on" class="h-8 w-8" src="../assets/images/video/video-on.svg" />
              <img v-else alt="on" class="h-8 w-8" src="../assets/images/video/video-off.svg" />
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center mt-6">
          <secondary-button @click="$modal.hide('sessionPreview')">
            {{ $t("cancel.generic") }}
          </secondary-button>

          <primary-button @click="joinSession"> {{ $t("video.join") }}</primary-button>
        </div>
      </div>
    </modal>
    <modal :adaptive="true" :width="420" height="auto" name="cancelSession">
      <div class="py-4 px-8">
        <h3 class="text-center font-bold mb-6 text-lg">{{ $t("session.cancel.title") }}</h3>

        <div class="relative">
          <h4 class="font-semibold text-13 mb-2">{{ $t("session.cancel.subtitle") }}</h4>
          <div class="rounded-xl bg-white shadow-lg cursor-pointer text-sm">
            <p
              v-for="(r, index) in reasons"
              :key="r"
              :class="{
                'bg-primaryBackgroundActive text-white': index === reason,
                'rounded-t-xl': index === 0,
                'rounded-b-xl': index === reasons.length - 1
              }"
              class="py-2 px-4 cursor-pointer border-b border-gray-300"
              @click="choseReason(index)"
            >
              {{ r }}
            </p>
          </div>
        </div>

        <div v-if="reason === 5" class="input-wrapper flex justify-start items-center mt-8 mb-4">
          <textarea v-model="comment" :placeholder="$t('session.cancel.reason')" type="text"></textarea>
        </div>

        <div class="flex justify-between items-center mt-6">
          <secondary-button @click="$modal.hide('cancelSession')"> {{ $t("back.generic") }}</secondary-button>
          <primary-button :disabled="reason === -1 || (reason === 5 && comment.length < 3)" @click="cancelAppointment">
            {{ $t("session.cancel.button") }}
          </primary-button>
        </div>
      </div>
    </modal>

    <h2 class="text-xl font-semibold">{{ $t("sessions.title") }}</h2>
    <div class="flex justify-start text-sm mt-4 mb-8">
      <p :class="{ 'text-primaryBackground': accepted }" class="mr-4 cursor-pointer" @click="accepted = true">
        {{ $t("sessions.accepted") }}
      </p>
      <p :class="{ 'text-primaryBackground': !accepted }" class="cursor-pointer" @click="accepted = false">
        {{ $t("sessions.cancelled") }}
      </p>
    </div>
    <div v-if="accepted">
      <div v-if="acceptedAppointments.length > 0">
        <div class="grid sessions-grid-system font-semibold px-6">
          <div class="col-span-4 sessions-item text-left justify-start">{{ $t("sessions.client.name") }}</div>
          <div class="col-span-2 sessions-item text-left justify-start">{{ $t("sessions.date") }}</div>
          <div class="col-span-2 sessions-item text-left justify-start">{{ $t("sessions.time") }}</div>
          <div class="col-span-4 sessions-item text-left justify-start">{{ $t("sessions.language") }}</div>
          <!--          <div class="col-span-2 sessions-item text-left justify-start">Session type</div>-->
        </div>
        <div v-for="(a, index) in acceptedAppointments" :key="a.id" class="w-full">
          <div class="grid sessions-grid-system my-2 text-sm w-full bg-white py-3 px-6 rounded-md mb-2">
            <div class="col-span-4 sessions-item text-left justify-start font-semibold">{{ a.client_full_name }}</div>
            <div class="col-span-2 sessions-item text-left justify-start">{{ getDate(a) }}</div>
            <div class="col-span-2 sessions-item text-left justify-start">{{ getTime(a) }}</div>
            <div class="col-span-3 sessions-item text-left justify-start">{{ a.coaching_language }}</div>
            <div
              v-if="expandedIndex !== index"
              class="col-span-2 sessions-item text-left justify-start text-primaryBackground font-semibold cursor-pointer"
              @click="expandedIndex = index"
            >
              {{ $t("sessions.info.show") }}
            </div>
            <div
              v-else
              class="col-span-2 sessions-item text-left justify-start text-primaryBackground font-semibold cursor-pointer"
              @click="expandedIndex = -1"
            >
              {{ $t("sessions.info.hide") }}
            </div>
            <div class="col-span-2 sessions-item text-left justify-end">
              <primary-button @click="openVideoSession(a.id)">
                {{ $t("join.generic") }}
              </primary-button>
            </div>
            <div
              v-if="expandedIndex === index"
              class="w-full col-span-full mt-4 flex justify-center border-t-2 border-neutralBackground3 pt-4"
            >
              <div class="w-1/2">
                <h3 class="font-semibold mb-2">{{ $t("selected.areas") }}</h3>
                <div class="flex flex-row justify-start flex-wrap">
                  <div
                    v-for="area in a.areas"
                    :key="area.id + 'area'"
                    class="mr-2 mb-2 rounded-full px-5 py-2 bg-white border-2 border-primaryBackground"
                  >
                    {{ area.name }}
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <h3 class="font-semibold mb-2">
                  {{ $t("comment.generic") }}
                </h3>
                <p>{{ a.comment }}</p>
                <div class="flex justify-end">
                  <primary-button class="mt-5" @click="openCancelAppointment(a.id)"
                    >{{ $t("cancel.generic") }}
                  </primary-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-full flex justify-center items-center flex-col mt-12">
        <img alt="no-sessions" class="w-16 h-16" src="../assets/images/calendar-empty-icon.svg" />
        <h2 class="text-inactiveGraphics mt-3">{{ $t("sessions.no.accepted") }}</h2>
      </div>
    </div>
    <div v-else>
      <div v-if="canceledAppointments.length > 0">
        <div class="grid sessions-grid-system font-semibold px-6">
          <div class="col-span-5 sessions-item text-left justify-start">{{ $t("sessions.client.name") }}</div>
          <div class="col-span-3 sessions-item text-left justify-start">{{ $t("sessions.date") }}</div>
          <div class="col-span-2 sessions-item text-left justify-start">{{ $t("sessions.time") }}</div>
          <div class="col-span-3 sessions-item text-left justify-start">{{ $t("sessions.who.cancel") }}</div>
          <div class="col-span-2 sessions-item text-left justify-start">{{ $t("sessions.cancellation.date") }}</div>
        </div>
        <div v-for="a in canceledAppointments" :key="a.id" class="w-full">
          <div class="grid sessions-grid-system my-2 text-sm w-full bg-white px-6 rounded-md mb-2">
            <div class="col-span-5 sessions-item text-left justify-start font-semibold">{{ a.client_full_name }}</div>
            <div class="col-span-3 sessions-item text-left justify-start">{{ getDate(a) }}</div>
            <div class="col-span-2 sessions-item text-left justify-start">{{ getTime(a) }}</div>
            <div class="col-span-3 sessions-item text-left justify-start">
              {{ a.status === "canceled_by_client" ? "Client" : "You" }}
            </div>
            <div class="col-span-2 sessions-item text-left justify-start">
              {{ getCancellationDate(a) }}
            </div>
            <!--        <div class="col-span-3 sessions-item text-left justify-end">-->
            <!--          <primary-button>Cancel</primary-button>-->
            <!--        </div>-->
          </div>
        </div>
      </div>
      <div v-else class="w-full flex justify-center items-center flex-col mt-12">
        <img alt="no-sessions" class="w-16 h-16" src="../assets/images/calendar-empty-icon.svg" />
        <h2 class="text-inactiveGraphics mt-3">{{ $t("sessions.no.canceled") }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import { mapState } from "vuex";
import dayjs from "dayjs";
import SecondaryButton from "@/components/ui-kit/buttons/SecondaryButton";

export default {
  name: "Sessions",
  components: { SecondaryButton, PrimaryButton },
  data() {
    return {
      accepted: true,
      expandedIndex: -1,
      isAudioOn: true,
      isVideoOn: true,
      selectedId: null,
      permissionDenied: false,
      reason: -1,
      reasons: [
        "Sickness",
        "Technical issues",
        "Conflicting with other appointments",
        "Person did not show up to last scheduled session",
        "Person not matching requirements for coaching (e.g needs professional treatment)",
        "Other"
      ],
      reasonOpened: false,
      comment: ""
    };
  },

  computed: {
    ...mapState("sessions", ["appointments", "acceptedAppointments", "canceledAppointments", "videoRoom"])
  },

  methods: {
    getDate(a) {
      if (!a) {
        return " - ";
      }
      return dayjs(a.start).format("DD-MM-YYYY");
    },

    getCancellationDate(a) {
      if (!a) {
        return " - ";
      }
      return dayjs(a.start).format("DD-MM-YYYY HH:mm");
    },
    getTime(a) {
      if (!a.session) {
        return " - ";
      }
      return dayjs(a.session.start).format("HH:mm");
    },

    showSession(start) {
      return dayjs(start) > dayjs();
    },

    openCancelAppointment(id) {
      this.selectedId = id;
      this.$modal.show("cancelSession");
    },

    async cancelAppointment() {
      this.$modal.hide("cancelSession");
      const reason = this.reason === 5 ? this.comment : this.reasons[this.reason];
      await this.$store.dispatch("sessions/cancelAppointment", { id: this.selectedId, reason });
    },

    async openVideoSession(id) {
      this.selectedId = id;

      this.$modal.show("sessionPreview");
      setTimeout(async () => {
        await this.createTestVideo();
      }, 1000);
    },

    async createTestVideo() {
      const video = this.$refs.testVideo;

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            video.srcObject = stream;
          })
          .catch((e) => {
            console.error("Something went wrong!", e);
            if (e.message === "Permission denied") {
              this.permissionDenied = true;
            }
          });
      }
    },

    removeTestVideo() {
      if (!this.$refs.testVideo || !this.$refs.testVideo.srcObject) {
        return;
      }
      const stream = this.$refs.testVideo.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      this.$refs.testVideo.srcObject = null;
    },

    closeModal() {
      this.removeTestVideo();
    },

    async joinSession() {
      this.removeTestVideo();
      await this.$router.push({
        name: "VideoSession",
        query: { videoRoomId: this.selectedId, isAudioOn: this.isAudioOn, isVideoOn: this.isVideoOn }
      });
    },

    choseReason(index) {
      this.reason = index;
      this.reasonOpened = false;
    }
  },

  async created() {
    await this.$store.dispatch("sessions/getAppointments");
  }
};
</script>

<style lang="scss" scoped>
.sessions-grid-system {
  grid-template-columns: repeat(15, minmax(0, 1fr));
  grid-auto-rows: minmax(60px, auto);
}

.sessions-item {
  @apply flex items-center;
}

.controls {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%);
}

.disclaimer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.video-bg {
  @apply w-full h-full bg-inactiveGraphics flex justify-center items-center text-white rounded-md absolute;
}

textarea {
  @apply p-3 text-primaryText rounded-xl border-2 border-inactiveText transition-all duration-300;
  resize: none;
  background: #fff;
  width: 500px;

  &.active {
    @apply h-40;
  }

  &::placeholder {
    @apply text-inactiveText;
  }

  &:focus {
    @apply border-primaryBackground;
    outline: none;
  }
}
</style>
