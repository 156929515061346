import axios from "axios";

export default class CommonClient {
  static async getLanguages() {
    return await axios.get("/api/content/languages");
  }

  static async getCoachingAreas() {
    return await axios.get("/api/content/areas");
  }
}
