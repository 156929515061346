<template>
  <p>
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "CaptionText"
};
</script>

<style scoped>
p {
  font-size: 13px;
}
</style>
