export function getMessage(e) {
  if (!e.response) {
    return e.message;
  }

  if (e.response.message) {
    return e.response.message;
  }

  if (e.response.data.message) {
    return e.response.data.message;
  }

  return "Something went wrong.";
}
