<template>
  <div class="px-24 py-12">
    <div class="flex justify-between items-start">
      <h2 class="text-xl font-semibold mb-10">{{ $t("sessions.log.title") }}</h2>
      <div class="flex items-center relative z-20">
        <p class="font-bold">{{ $t("filter.status") }}</p>
        <StatusFilter @status-changed="statusChanged" />
        <p class="font-bold">{{ $t("filter.date") }}</p>
        <DateFilter @date-changed="dateChanged" />
      </div>
    </div>
    <div v-if="showCustom" class="w-full flex justify-end relative z-10">
      <DateRangePicker
        v-model="dateRange"
        :autoApply="true"
        :date-range="dateRange"
        :showDropdowns="false"
        :showRanges="false"
        :showWeekNumbers="true"
        :time-picker="false"
        :timePicker24Hour="false"
        class="w-80"
        singleDatePicker="range"
      ></DateRangePicker>
      <primary-button
        :disabled="!dateRange.startDate && !dateRange.endDate"
        class="mr-12 ml-6"
        @click="searchCustomDate"
      >
        {{ $t("search.generic") }}
      </primary-button>
    </div>

    <div v-if="pastAppointments.length > 0" class="mt-12">
      <div class="grid sessions-grid-system font-semibold px-6">
        <div class="col-span-4 sessions-item text-left justify-start">{{ $t("sessions.client.name") }}</div>
        <!--        <div class="col-span-3 sessions-item text-left justify-start">{{ $t("sessions.client.email") }}</div>-->
        <div class="col-span-3 sessions-item text-left justify-start">{{ $t("sessions.date") }}</div>
        <div class="col-span-2 sessions-item text-left justify-start">{{ $t("sessions.time") }}</div>
        <!--        <div class="col-span-3 sessions-item text-left justify-start">Language preferences</div>-->
        <div class="col-span-2 sessions-item text-left justify-start">{{ $t("sessions.status") }}</div>
        <!--          <div class="col-span-2 sessions-item text-left justify-start">Session type</div>-->
      </div>
      <div
        v-for="(a, index) in pastAppointments"
        :key="a.id"
        class="grid sessions-grid-system my-2 text-sm w-full bg-white py-3 px-6 rounded-md mb-2"
      >
        <div class="col-span-4 sessions-item text-left justify-start items-center font-semibold">
          {{ a.client_full_name }}
        </div>
        <!--        <div class="col-span-3 sessions-item text-left justify-start font-semibold truncate">{{ a.client_email }}</div>-->
        <div class="col-span-3 sessions-item text-left justify-start">{{ getDate(a) }}</div>
        <div class="col-span-2 sessions-item text-left justify-start">{{ getTime(a) }}</div>
        <!--        <div class="col-span-3 sessions-item text-left justify-start">English</div>-->
        <div
          :class="getStatus(a.status) === 'Accepted' ? 'text-green-400' : 'text-red-400'"
          class="col-span-2 sessions-item text-left justify-start font-semibold"
        >
          {{ getStatus(a.status) }}
        </div>
        <div
          v-if="expandedIndex !== index"
          class="col-span-2 sessions-item text-right justify-end text-primaryBackground font-semibold cursor-pointer"
          @click="expandedIndex = index"
        >
          {{ $t("sessions.info.show") }}
        </div>
        <div
          v-else
          class="col-span-2 sessions-item text-right justify-end text-primaryBackground font-semibold cursor-pointer"
          @click="expandedIndex = -1"
        >
          {{ $t("sessions.info.hide") }}
        </div>
        <!--        <div class="col-span-2 sessions-item text-left justify-end">-->
        <!--          <primary-button @click="openVideoSession(a.id)">Join</primary-button>-->
        <!--        </div>-->
        <div
          v-if="expandedIndex === index"
          class="w-full col-span-full mt-4 flex justify-center border-t-2 border-neutralBackground3 pt-4"
        >
          <div class="w-1/2">
            <h3 class="font-semibold mb-2">
              {{ $t("selected.areas") }}
            </h3>
            <div class="flex flex-row justify-start flex-wrap">
              <div
                v-for="area in a.areas"
                :key="area.id + 'area'"
                class="mr-2 mb-2 rounded-full px-5 py-2 bg-white border-2 border-primaryBackground"
              >
                {{ area.name }}
              </div>
            </div>
          </div>
          <div class="w-1/2">
            <h3 class="font-semibold mb-2">{{ $t("comment.generic") }}</h3>
            <p>{{ a.comment }}</p>
            <div class="flex justify-end">
              <!--              <primary-button class="mt-5" @click="openCancelAppointment(a.id)">Cancel</primary-button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full flex justify-center items-center flex-col mt-12">
      <img alt="no-sessions" class="w-16 h-16" src="../assets/images/calendar-empty-icon.svg" />
      <h2 class="text-inactiveGraphics mt-3">
        {{ $t("sessions.log.info") }}
      </h2>
    </div>
    <ScrollObserver v-if="pastAppointmentsPagination.next && !isLoading" class="mt-6" @intersect="loadSessions" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import ScrollObserver from "@/components/ScrollObserver";
import StatusFilter from "@/components/session-log/StatusFilter";
import DateFilter from "@/components/session-log/DateFilter";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";

export default {
  name: "SessionsLog",
  components: { PrimaryButton, DateFilter, StatusFilter, ScrollObserver, DateRangePicker },
  data() {
    return {
      expandedIndex: -1,
      statusFilter: "",
      dateFilter: "",
      isLoading: false,
      startDate: "",
      endDate: "",
      showCustom: false,
      dateRange: {
        startDate: null,
        endDate: null
      }
    };
  },
  computed: {
    ...mapState("sessions", ["pastAppointments", "pastAppointmentsPagination"])
  },

  methods: {
    getDate(a) {
      if (!a) {
        return " - ";
      }
      return dayjs(a.start).format("DD-MM-YYYY");
    },
    getTime(a) {
      if (!a) {
        return " - ";
      }
      return dayjs(a.start).format("HH:mm");
    },

    getStatus(s) {
      if (s === "accepted") {
        return this.$t("filter.status.2");
      } else if (s === "canceled_by_client") {
        return this.$t("filter.status.3");
      } else if (s === "canceled_by_coach") {
        return this.$t("filter.status.4");
      } else if (s === "canceled_by_humanoo") {
        return this.$t("filter.status.5");
      } else {
        return this.$t("sessions.cancelled");
      }
    },
    async loadSessions() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      let statusBE = "";

      switch (this.statusFilter) {
        case this.$t("filter.status.2"):
          statusBE = "accepted";
          break;
        case this.$t("filter.status.3"):
          statusBE = "canceled_by_client";
          break;
        case this.$t("filter.status.4"):
          statusBE = "canceled_by_coach";
          break;
        case this.$t("filter.status.5"):
          statusBE = "canceled_by_humanoo";
          break;

        case this.$t("filter.status.1"):
          statusBE = "";
      }

      if (this.pastAppointmentsPagination.next) {
        await this.$store.dispatch("sessions/getPastAppointments", {
          status: statusBE,
          start: this.startDate,
          end: this.endDate
        });
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },

    async statusChanged(status) {
      this.statusFilter = status;

      await this.$store.dispatch("sessions/refreshPastAppointments");
      await this.loadSessions();
    },

    async dateChanged(date) {
      if (date === this.$t("filter.date.4")) {
        this.showCustom = true;
        return;
      }

      this.showCustom = false;

      if (date === this.$t("filter.date.1")) {
        this.endDate = "";
        this.startDate = "";
      }

      if (date === this.$t("filter.date.2")) {
        this.endDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
        this.startDate = dayjs().subtract(7, "day").format("YYYY-MM-DD HH:mm:ss");
      }

      if (date === this.$t("filter.date.3")) {
        this.endDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
        this.startDate = dayjs().subtract(30, "day").format("YYYY-MM-DD HH:mm:ss");
      }

      await this.$store.dispatch("sessions/refreshPastAppointments");
      await this.loadSessions();
    },

    async searchCustomDate() {
      await this.$store.dispatch("sessions/refreshPastAppointments");
      this.startDate = dayjs(this.dateRange.startDate).format("YYYY-MM-DD 00:00:00");
      this.endDate = dayjs(this.dateRange.endDate).format("YYYY-MM-DD 23:59:59");
      await this.loadSessions();
    }
  },

  async created() {
    this.statusFilter = this.$route.query.status;
    await this.loadSessions();
  }
};
</script>

<style scoped>
.sessions-grid-system {
  grid-template-columns: repeat(15, minmax(0, 1fr));
  grid-auto-rows: minmax(60px, auto);
}

.sessions-item {
  @apply flex items-center;
}

.w-80 {
  width: 16rem;
}
</style>
