<template>
  <div id="app">
    <LanguageModal />
    <notifications group="notifications" />
    <router-view />
  </div>
</template>
<script>
import storageClient from "@/services/storageClient";
import LanguageModal from "@/components/LanguageModal";

export default {
  name: "App",
  components: { LanguageModal },
  async created() {
    if (storageClient.getLanguage()) {
      await this.$store.dispatch("locale/setLocale", storageClient.getLanguage());
    } else {
      storageClient.setLanguage("en-US");
    }

    // const { data } = await axios.post("/api/coach/auth/forgot-password", { email: "qa+1@nerdzlab.com" });
    //
    // console.log(data);
  }
};
</script>
<style>
.daterangepicker.show-calendar .ranges {
  display: none !important;
}

@media screen and (min-width: 339px) {
  .daterangepicker.single.show-ranges.show-weeknumbers,
  .daterangepicker.single.show-ranges {
    min-width: 290px !important;
  }
}

.reportrange-text {
  border: none !important;
  border-radius: 10px;
  padding: 10px 12px !important;
  text-align: center !important;
}
</style>
