import CommonClient from "@/services/commonClient";

export const namespaced = true;

export const state = {
  languages: [],
  coachingAreas: []
};

export const mutations = {
  SET_LANGUAGES(state, languages) {
    state.languages = languages;
  },
  SET_COACHING_AREAS(state, areas) {
    state.coachingAreas = areas;
  }
};

export const actions = {
  async getLanguages({ commit }) {
    const { data } = await CommonClient.getLanguages();
    commit("SET_LANGUAGES", data);
  },

  async getCoachingAreas({ commit }) {
    const { data } = await CommonClient.getCoachingAreas();
    commit("SET_COACHING_AREAS", data);
  }
};
