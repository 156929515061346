import axios from "axios";

export default class AuthClient {
  static async signUp(user) {
    return await axios.post("/api/coach/auth/registration", user);
  }

  static async signIn(user) {
    return await axios.post("/api/coach/auth/login", user);
  }

  static async getUser() {
    return await axios.get("/api/coach/profile");
  }

  static async updateProfile(user) {
    return await axios.post("/api/coach/profile", user);
  }

  static async resetPassword(options) {
    return await axios.post("/api/coach/auth/reset-password", options);
  }

  static async forgotPassword(options) {
    return await axios.post("/api/coach/auth/forgot-password", options);
  }
}
