<template>
  <div
    :class="acceptedSlot ? 'bg-green-400' : 'bg-primaryBackground'"
    class="px-3 py-2 mr-2 mb-2 rounded-xl relative slot"
  >
    <div
      v-if="acceptedSlot"
      v-tooltip="{
        content: $t('timeslot.booked'),
        classes: 'bg-inactiveGraphics text-white px-2 py-1 rounded-md text-sm mb-1'
      }"
      class="absolute top-0 left-0 w-full h-full"
    ></div>
    <div v-if="!acceptedSlot" class="close-button" @click="$emit('remove-slot', availabilitySlot)"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AvailabilitySlot",
  props: {
    availabilitySlot: null,
    acceptedSlot: Boolean
  }
};
</script>

<style lang="scss" scoped>
.close-button {
  background-image: url("../../assets/images/close.svg");
  background-position: center center;
  background-size: cover;
  @apply w-4 h-4 rounded-full bg-inactiveGraphics absolute hidden p-2 cursor-pointer;
  top: -4px;
  right: -4px;
}

.slot:hover .close-button {
  @apply block;
}
</style>
