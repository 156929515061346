<template>
  <div class="min-h-screen bg-neutralBackground3">
    <div class="w-full h-16 flex justify-between items-center px-24 bg-white shadow-md col-span-7">
      <div class="flex justify-start items-center">
        <router-link class="text-main font-black text-2xl" to="/profile">Humanoo</router-link>
        <router-link active-class="text-primaryBackground" class="ml-12 hover:text-primaryBackground" to="/profile">
          {{ $t("navbar.profile") }}
        </router-link>
        <router-link
          :to="{ name: 'Sessions' }"
          active-class="text-primaryBackground"
          class="ml-12 hover:text-primaryBackground"
        >
          {{ $t("navbar.sessions") }}
        </router-link>
        <router-link
          :to="{ name: 'Availability' }"
          active-class="text-primaryBackground"
          class="ml-12 hover:text-primaryBackground"
        >
          {{ $t("navbar.availability") }}
        </router-link>
        <router-link
          :to="{ name: 'SessionsLog' }"
          active-class="text-primaryBackground"
          class="ml-12 hover:text-primaryBackground"
        >
          {{ $t("navbar.sessions.log") }}
        </router-link>
      </div>
      <div class="flex items-center">
        <p class="mr-12 font-semibold cursor-pointer" @click="$modal.show('language')">{{ getLocale }}</p>
        <primary-button @click="logout">{{ $t("logout.button") }}</primary-button>
      </div>
    </div>

    <RateAppModal />

    <router-view />
  </div>
</template>

<script>
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import { mapState } from "vuex";
import RateAppModal from "@/components/rate-app/RateAppModal";

export default {
  name: "Main",
  components: { RateAppModal, PrimaryButton },
  computed: {
    ...mapState("sessions", ["lastSession"]),
    ...mapState("locale", ["locale"]),
    getLocale() {
      let locale;
      switch (this.locale) {
        case "en-US":
          locale = "English";
          break;
        case "fr-FR":
          locale = "Français";
          break;
        case "it-IT":
          locale = "Italiano";
          break;
        case "de-DE":
          locale = "Deutsch";
          break;
      }
      return locale;
    }
  },

  watch: {
    async $route() {
      await this.$store.dispatch("sessions/getLastSession");
      if (!this.lastSession.app_feedback_coach) {
        this.$modal.show("rate-app");
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>

<style scoped></style>
