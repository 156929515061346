<template>
  <div>
    <div
      :class="{ 'input-error': errors.length > 0, active, textarea }"
      class="input-wrapper flex justify-start items-center"
    >
      <slot></slot>
      <primary-button v-if="active" class="ml-4" sm @click="$emit('save')">
        {{ $t("save.generic") }}
      </primary-button>
      <secondary-button v-if="active" class="ml-2" sm @click="$emit('cancel')">
        {{ $t("cancel.generic") }}
      </secondary-button>
    </div>
    <p v-if="errors.length > 0" class="text-error text-13 ml-6 mt-1">{{ errors[0] }}</p>
  </div>
</template>

<script>
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import SecondaryButton from "@/components/ui-kit/buttons/SecondaryButton";

export default {
  name: "HiddenInput",
  components: { SecondaryButton, PrimaryButton },
  props: {
    errors: {
      default() {
        return [];
      }
    },
    active: Boolean,
    textarea: Boolean
  }
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
}

input:disabled {
  @apply bg-white;
}

.input-wrapper.active {
  input {
    @apply w-full px-3 pl-5 py-1 text-primaryText border border-inactiveText transition-all duration-300;
    border-radius: 20px;
    outline: none;
  }

  input::placeholder {
    @apply text-inactiveText;
  }

  input:focus {
    @apply border-primaryBackground;
  }

  &.textarea {
    textarea {
      @apply h-40;
      width: 500px;
      resize: none;
    }
  }
}

textarea {
  @apply p-3 text-primaryText rounded-xl border-2 border-inactiveText transition-all duration-300;
  height: 0;
  resize: none;
  background: #fff;

  &.active {
    @apply h-40;
  }

  &::placeholder {
    @apply text-inactiveText;
  }
}

textarea:focus {
  @apply border-primaryBackground;
  outline: none;
}

.input-error.input-wrapper {
  input {
    @apply border-error;
  }
}
</style>
