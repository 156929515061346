<template>
  <modal :adaptive="true" :width="420" height="auto" name="rate-app">
    <div class="py-4 px-8">
      <h3 class="text-center font-bold mb-6 text-lg">{{ $t("rate.app.experience") }}</h3>
      <RatingStars :value="rating" class="mt-4" @click="setRating" />
      <div class="input-wrapper flex justify-start items-center mt-8 mb-4">
        <textarea v-model="comment" :placeholder="$t('rate.app.tell')" type="text"></textarea>
      </div>
      <div class="flex justify-between items-center">
        <secondary-button @click="cancelRate">{{ $t("cancel.generic") }}</secondary-button>
        <primary-button :disabled="rating === 0" @click="sendRate">{{ $t("submit.generic") }}</primary-button>
      </div>
    </div>
  </modal>
</template>

<script>
import RatingStars from "@/components/rate-app/RatingStars";
import SecondaryButton from "@/components/ui-kit/buttons/SecondaryButton";
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import { mapState } from "vuex";
import SessionsClient from "@/services/sessionsClient";

export default {
  name: "RateAppModal",
  components: { PrimaryButton, SecondaryButton, RatingStars },
  data() {
    return {
      rating: 0,
      comment: ""
    };
  },

  computed: {
    ...mapState("sessions", ["lastSession"])
  },

  methods: {
    setRating(rating) {
      this.rating = rating;
    },

    async sendRate() {
      await SessionsClient.rateAppExperience(this.lastSession.id, this.rating, this.comment || "");
      this.$modal.hide("rate-app");
    },
    async cancelRate() {
      await SessionsClient.rateAppExperience(this.lastSession.id, 0, "");
      this.$modal.hide("rate-app");
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  @apply p-3 text-primaryText rounded-xl border-2 border-inactiveText transition-all duration-300;
  resize: none;
  background: #fff;
  width: 500px;

  &.active {
    @apply h-40;
  }

  &::placeholder {
    @apply text-inactiveText;
  }

  &:focus {
    @apply border-primaryBackground;
    outline: none;
  }
}
</style>
