<template>
  <div class="auth md:grid-cols-4 xs:grid-cols-3 xs:px-4">
    <large-bold class="absolute w-full top-0 left-0 py-8 text-center text-primaryText">Humanoo</large-bold>
    <div class="auth-container xs:col-span-3 xs:col-start-1 md:col-span-2 md:col-start-2 w-full">
      <router-view />
    </div>
    <copyright class="absolute w-full bottom-0 left-0 py-8 text-center" />
  </div>
</template>

<script>
import LargeBold from "@/components/ui-kit/typography/LargeBold";
import Copyright from "@/components/Copyright";

export default {
  name: "Auth",
  components: { Copyright, LargeBold }
};
</script>

<style scoped>
.auth {
  @apply bg-neutralBackground3 h-screen w-full flex justify-center flex-col items-center grid grid-cols-3;
}

.auth-container {
  @apply bg-white shadow-sm px-6 py-8 rounded-xl col-span-1 col-start-2;
}
</style>
