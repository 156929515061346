import axios from "axios";
import { getMessage } from "@/utils/extractMessage";
import storageClient from "@/services/storageClient";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";

dayjs.extend(utc);
dayjs.extend(timezone);

export function axiosSetup(vue) {
  let locale;
  switch (storageClient.getLanguage()) {
    case "de-DE":
      locale = "de";
      break;
    case "it-IT":
      locale = "it";
      break;
    case "fr-FR":
      locale = "fr";
      break;
    case "en-US":
      locale = "en";
      break;
    default:
      locale = "en";
      break;
  }
  axios.defaults.headers["Accept-Language"] = locale;
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
  axios.defaults.headers["Accept"] = "application/json";
  axios.defaults.headers["Content-Type"] = "application/json";
  axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers["X-Timezone"] = dayjs.tz.guess();

  axios.interceptors.request.use((config) => {
    const token = storageClient.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    (r) => r,
    (e) => {
      vue.notify({
        group: "notifications",
        title: "Something went wrong",
        text: getMessage(e),
        type: "error"
      });
      return Promise.reject(e);
    }
  );
}
