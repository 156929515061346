<template>
  <div class="flex flex-row justify-evenly">
    <RatingStar :full="rating > 0" @click="$emit('click', 1)" @mouseenter="full = 1" @mouseleave="full = 0" />
    <RatingStar :full="rating > 1" @click="$emit('click', 2)" @mouseenter="full = 2" @mouseleave="full = 0" />
    <RatingStar :full="rating > 2" @click="$emit('click', 3)" @mouseenter="full = 3" @mouseleave="full = 0" />
    <RatingStar :full="rating > 3" @click="$emit('click', 4)" @mouseenter="full = 4" @mouseleave="full = 0" />
    <RatingStar :full="rating > 4" @click="$emit('click', 5)" @mouseenter="full = 5" @mouseleave="full = 0" />
  </div>
</template>

<script>
import RatingStar from "@/components/rate-app/RatingStar";

export default {
  name: "RatingStars",
  components: { RatingStar },
  props: {
    value: null
  },
  data() {
    return {
      full: 0
    };
  },

  computed: {
    rating() {
      if (this.value > 0) {
        return this.value;
      } else {
        return this.full;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
