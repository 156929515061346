<template>
  <button
    :class="sm ? 'px-3 py-1 text-sm font-normal' : 'px-6 py-2 font-bold'"
    :disabled="disabled"
    class="rounded-30 outline-none"
    @click.prevent="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  props: {
    disabled: Boolean,
    sm: Boolean
  }
};
</script>

<style scoped>
button {
  @apply bg-primaryBackground;
}

button:active {
  @apply bg-primaryBackgroundActive;
}

button:focus {
  outline: none;
}

button:disabled {
  @apply bg-inactiveGraphics text-inactiveText;
}
</style>
