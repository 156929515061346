<template>
  <div class="h-screen w-screen flex justify-center items-start">
    <div class="initial-setup md:grid-cols-4 xs:grid-cols-3 xs:px-4">
      <div class="initial-setup-container xs:col-span-3 xs:col-start-1 md:col-span-2 md:col-start-2 w-full">
        <div class="w-full flex flex-col justify-center items-center">
          <div class="w-full flex justify-between items-center mb-8">
            <title-text class="text-center mx-auto">{{ $t("initial.setup.title") }}</title-text>
          </div>
          <body-bold class="my-4 text-left w-full">{{ $t("initial.setup.subtitle") }}</body-bold>

          <main-input :errors="nameErrors" class="w-full mb-4">
            <input
              v-model="name"
              :placeholder="$t('name.generic')"
              class="w-full"
              type="text"
              @blur="$v.name.$touch()"
            />
          </main-input>
          <main-input :errors="expertiseErrors" class="w-full">
            <input
              v-model="expertise"
              :placeholder="$t('coach.info.1')"
              class="w-full"
              type="text"
              @blur="$v.expertise.$touch()"
            />
          </main-input>
          <body-bold class="my-4 text-left w-full">{{ $t("coach.info.4") }}</body-bold>
          <div class="flex justify-start items-center flex-wrap w-full">
            <div
              v-for="l in languages"
              :key="l.id"
              :class="selectedLanguages.indexOf(l.id) >= 0 ? 'bg-black text-white' : 'text-black'"
              class="language mr-2 mb-2 flex items-center justify-center"
              @click="choseLanguage(l.id)"
            >
              <img :alt="l.name" :src="l.media" class="w-6 mr-2" />
              {{ l.name }}
            </div>
          </div>
          <p v-if="languagesErrors.length > 0" class="text-error text-13 ml-6 my-1 w-full text-left">
            {{ languagesErrors[0] }}
          </p>

          <primary-button class="mt-8" @click="updateProfile">{{ $t("update.profile") }}</primary-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleText from "@/components/ui-kit/typography/TitleText";
import MainInput from "@/components/ui-kit/form/MainInput";
import { mapState } from "vuex";
import BodyBold from "@/components/ui-kit/typography/BodyBold";
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";

import { maxLength, minLength, required } from "vuelidate/lib/validators";

const nameValidator = (value) => {
  return value.indexOf(" ") !== -1 && value.length > value.indexOf(" ") + 2;
};
export default {
  name: "InitialSetup",
  components: { PrimaryButton, BodyBold, MainInput, TitleText },
  data() {
    return {
      name: "",
      expertise: "",
      selectedLanguages: [],
      languagesErrors: [],
      updating: false
    };
  },

  computed: {
    ...mapState("common", ["languages"]),
    ...mapState("auth", ["user"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push(this.$t("name.required"));
      !this.$v.name.minLength && errors.push(this.$t("name.longer", { count: "1" }));
      !this.$v.name.nameValidator && errors.push(this.$t("name.two.strings"));
      return errors;
    },
    expertiseErrors() {
      const errors = [];
      if (!this.$v.expertise.$dirty) return errors;
      !this.$v.expertise.required && errors.push(this.$t("expertise.required", { count: "1" }));
      !this.$v.expertise.minLength && errors.push(this.$t("expertise.longer", { count: "2" }));
      !this.$v.expertise.maxLength && errors.push(this.$t("expertise.shorter", { count: "201" }));
      return errors;
    }
  },

  validations: {
    name: {
      required,
      minLength: minLength(2),
      nameValidator
    },
    expertise: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200)
    }
  },

  methods: {
    choseLanguage(item) {
      if (this.selectedLanguages.indexOf(item) > -1) {
        this.selectedLanguages.splice(this.selectedLanguages.indexOf(item), 1);
      } else {
        this.selectedLanguages.push(item);
        this.languagesErrors = [];
      }
    },

    async updateProfile() {
      if (this.updating) {
        return;
      }
      this.updating = true;
      this.$v.$touch();
      if (this.$v.$invalid || this.selectedLanguages.length === 0) {
        if (this.selectedLanguages.length === 0) {
          this.languagesErrors.push(this.$t("choose.language", { count: "1" }));
        }
        this.updating = false;
        return;
      }

      this.updating = false;

      await this.$store.dispatch("auth/updateProfile", {
        name: this.name,
        expertise: this.expertise,
        language: this.selectedLanguages
      });

      await this.$router.push({ name: "Home" });
    }
  },

  async created() {
    await this.$store.dispatch("common/getLanguages");
    this.name = this.user.name;
  }
};
</script>

<style lang="scss" scoped>
.initial-setup {
  @apply bg-neutralBackground3 h-screen w-full flex justify-center flex-col items-center grid grid-cols-3;
}

.initial-setup-container {
  @apply bg-white shadow-sm px-6 py-8 rounded-xl col-span-1 col-start-2;
}

.language {
  @apply rounded-full px-5 py-2 border-2 border-black transition-all duration-300 cursor-pointer;
}
</style>
