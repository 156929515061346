import Vue from "vue";
import Vuex from "vuex";
import * as auth from "./modules/auth";
import * as common from "./modules/common";
import * as sessions from "./modules/sessions";
import * as locale from "./modules/locale";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { auth, common, sessions, locale }
});
