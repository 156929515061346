import authClient from "@/services/authClient";
import storageClient from "@/services/storageClient";
import router from "../../router";

export const namespaced = true;

export const state = {
  user: null,
  token: storageClient.getToken() || null
};

export const mutations = {
  AUTH_SUCCESS(state, data) {
    state.user = data.data;
    state.token = data.token.access_token;
  },

  SET_USER(state, user) {
    state.user = user;
  },

  LOGOUT(state) {
    state.token = null;
  }
};

export const actions = {
  async signUp({ commit, dispatch }, user) {
    const { data } = await authClient.signUp(user);

    storageClient.setToken(data.token.access_token);
    commit("AUTH_SUCCESS", data);
    await dispatch("getUser");
    await router.push({ name: "Home" });
  },

  async signIn({ commit, dispatch }, user) {
    const { data } = await authClient.signIn(user);

    storageClient.setToken(data.token.access_token);
    await commit("AUTH_SUCCESS", data);
    await dispatch("getUser");
    await router.push({ name: "Sessions" });
  },

  async getUser({ commit }) {
    const { data } = await authClient.getUser();
    commit("SET_USER", data);
  },

  async updateProfile({ commit }, user) {
    const { data } = await authClient.updateProfile(user);
    commit("SET_USER", data);
  },

  logout({ commit }) {
    storageClient.removeToken();
    commit("LOGOUT");
    return router.push({ name: "SignIn" });
  }
};

export const getters = {
  isLoggedIn: (state) => !!state.token,
  user: (state) => state.user
};
