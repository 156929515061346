<template>
  <div class="w-full flex flex-col justify-center items-center">
    <div class="w-full flex justify-between items-center mb-8">
      <title-text>{{ $t("sign.up.generic") }}</title-text>
    </div>
    <main-input :errors="emailErrors" class="w-full mb-4">
      <input v-model="email" :placeholder="$t('email.generic')" class="w-full" type="email" @blur="$v.email.$touch()" />
    </main-input>
    <main-input
      :errors="passwordErrors"
      class="w-full mb-4"
      password
      @change-to-text="passwordType = 'text'"
      @change-to-password="passwordType = 'password'"
    >
      <input
        v-model="password"
        :placeholder="$t('password.generic')"
        :type="passwordType"
        class="w-full"
        @blur="$v.password.$touch()"
      />
    </main-input>
    <main-input
      :errors="passwordConfirmationErrors"
      class="w-full mb-4"
      password
      @change-to-text="passwordConfirmationType = 'text'"
      @change-to-password="passwordConfirmationType = 'password'"
    >
      <input
        v-model="passwordConfirmation"
        :placeholder="$t('password.confirmation')"
        :type="passwordConfirmationType"
        class="w-full"
        @blur="$v.passwordConfirmation.$touch()"
      />
    </main-input>
    <main-input :errors="nameErrors" class="w-full mb-8">
      <input v-model="name" :placeholder="$t('name.generic')" class="w-full" type="text" @blur="$v.name.$touch()" />
    </main-input>

    <primary-button :disabled="$v.$anyError" @click="signUp">
      {{ $t("sign.up.generic") }}
    </primary-button>
  </div>
</template>

<script>
import TitleText from "@/components/ui-kit/typography/TitleText";
import MainInput from "@/components/ui-kit/form/MainInput";
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import { email, maxLength, minLength, required, sameAs } from "vuelidate/lib/validators";

const nameValidator = (value) => {
  return value.indexOf(" ") !== -1 && value.length > value.indexOf(" ") + 2;
};

export default {
  name: "SignUp",
  components: { PrimaryButton, MainInput, TitleText },
  data() {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      name: "",
      passwordType: "password",
      passwordConfirmationType: "password",
      loading: false
    };
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push(this.$t("email.required"));
      !this.$v.email.email && errors.push(this.$t("email.valid"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push(this.$t("name.required"));
      !this.$v.name.minLength && errors.push(this.$t("name.longer", { count: "2" }));
      !this.$v.name.nameValidator && errors.push(this.$t("name.two.strings"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push(this.$t("password.required"));
      !this.$v.password.minLength && errors.push(this.$t("password.longer", { count: "9" }));
      !this.$v.password.maxLength && errors.push(this.$t("password.shorter", { count: "31" }));
      const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
      if (!strongRegex.test(this.password)) {
        errors.push(this.$t("password.contain"));
      }
      return errors;
    },
    passwordConfirmationErrors() {
      const errors = [];
      if (!this.$v.passwordConfirmation.$dirty) return errors;
      !this.$v.passwordConfirmation.required && errors.push(this.$t("password.confirmation.required"));
      !this.$v.passwordConfirmation.sameAsPassword && errors.push(this.$t("password.confirmation.equal"));
      return errors;
    }
  },

  validations: {
    name: {
      required,
      minLength: minLength(3),
      nameValidator
    },
    password: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(30)
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs("password")
    },
    email: {
      required,
      email
    }
  },

  methods: {
    async signUp() {
      this.$v.$touch();
      if (this.$v.$invalid || this.loading) {
        return;
      }

      this.loading = true;
      try {
        await this.$store.dispatch("auth/signUp", {
          email: this.email,
          password: this.password,
          name: this.name
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
