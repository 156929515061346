<template>
  <div class="h-full">
    <div v-show="videoOn" id="localTrack" class="relative z-40"></div>
    <div class="w-full flex flex-row justify-center items-center h-full">
      <div id="remoteTrack" class="flex-1">
        <div v-if="!isParticipantConnected" class="flex justify-center items-center h-f">
          <p class="text-center">{{ $t("video.waiting", { name: activeAppointment.client_full_name }) }}</p>
        </div>
        <div
          v-if="!remoteAudioEnabled && isParticipantConnected"
          class="audio-disabled px-2 py-1 pr-3 rounded-md text-white flex items-center"
        >
          <span class="mr-2">
            <svg height="20" version="1.1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19,11C19,12.19 18.66,13.3 18.1,14.28L16.87,13.05C17.14,12.43 17.3,11.74 17.3,11H19M15,11.16L9,5.18V5A3,3 0 0,1 12,2A3,3 0 0,1 15,5V11L15,11.16M4.27,3L21,19.73L19.73,21L15.54,16.81C14.77,17.27 13.91,17.58 13,17.72V21H11V17.72C7.72,17.23 5,14.41 5,11H6.7C6.7,14 9.24,16.1 12,16.1C12.81,16.1 13.6,15.91 14.31,15.58L12.65,13.92L12,14A3,3 0 0,1 9,11V10.28L3,4.27L4.27,3Z"
                fill="#D82E2C"
              />
            </svg>
          </span>
          {{ activeAppointment.client_full_name }} {{ $t("video.is.muted") }}
        </div>
        <div
          v-if="!remoteVideoEnabled && isParticipantConnected"
          class="w-full bg-inactiveGraphics h-f absolute z-30 flex justify-center items-center"
        >
          <div class="flex flex-col justify-center items-center">
            <p class="text-white text-center px-3">
              {{ activeAppointment.client_full_name || "Client" }} {{ $t("video.disabled.camera") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="absolute bg-black bg-opacity-50 flex rounded-xl video-controls z-40">
      <div class="py-4 px-6 cursor-pointer" @click="changeAudio">
        <img v-if="audioOn" alt="on" class="h-8 w-8" src="../assets/images/video/micro-on.svg" />
        <img v-else alt="on" class="h-8 w-8" src="../assets/images/video/micro-off.svg" />
      </div>
      <div class="py-4 px-6 cursor-pointer" @click="changeVideo">
        <img v-if="videoOn" alt="on" class="h-8 w-8" src="../assets/images/video/video-on.svg" />
        <img v-else alt="on" class="h-8 w-8" src="../assets/images/video/video-off.svg" />
      </div>
      <div class="h-16 w-20 flex justify-center items-center">
        <div class="px-2 rounded-md bg-red-600 flex justify-center items-center py-1 cursor-pointer" @click="leaveCall">
          <img alt="on" src="../assets/images/video/phone.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Twilio, { createLocalVideoTrack } from "twilio-video";
import { mapState } from "vuex";

export default {
  name: "VideoSession",
  data() {
    return {
      videoRoom1: {
        access_token: "",
        sid: ""
      },
      activeRoom: null,
      localVideoTracks: null,
      showInputs: true,
      remoteVideoEnabled: false,
      remoteAudioEnabled: true,
      isParticipantConnected: false,
      audioOn: false,
      videoOn: false
    };
  },

  //todo check initial remote audio enabled

  computed: {
    ...mapState("sessions", ["videoRoom", "activeAppointment"])
  },

  methods: {
    async createTwilioVideo() {
      Twilio.connect(this.videoRoom.token, { name: this.videoRoom.sid }).then(async (room) => {
        this.activeRoom = room;

        this.localVideoTracks = await createLocalVideoTrack();

        let localMediaContainer = document.getElementById("localTrack");
        localMediaContainer.appendChild(this.localVideoTracks.attach());

        this.activeRoom.participants.forEach((participant) => {
          this.participantConnected(participant);
          if (!participant.videoTracks.entries().next().value[1].isTrackEnabled) {
            this.remoteVideoEnabled = false;
          }
        });

        if (!this.audioOn) {
          this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
            publication.track.disable();
          });
        }

        if (!this.videoOn) {
          this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
            publication.track.disable();
          });
        }

        this.activeRoom.on("participantConnected", this.participantConnected);
        this.activeRoom.on("participantDisconnected", this.participantDisconnected);
      });
    },

    participantConnected(participant) {
      const div = document.getElementById("remoteTrack");

      participant.on("trackSubscribed", (track) => this.trackSubscribed(div, track));
      participant.on("trackUnsubscribed", this.trackUnsubscribed);

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          this.trackSubscribed(div, publication.track);
        }
      });

      participant.on("trackDisabled", this.handleTrackDisabled);
      participant.on("trackEnabled", this.handleTrackEnabled);

      this.isParticipantConnected = true;

      setTimeout(() => {
        this.remoteVideoEnabled = participant.videoTracks.entries().next().value[1].isTrackEnabled;
      }, 2000);
    },

    participantDisconnected() {
      this.isParticipantConnected = false;
      this.remoteVideoEnabled = false;
    },

    trackSubscribed(div, track) {
      console.log(div, "subscribed");
      div.appendChild(track.attach());
    },

    trackUnsubscribed(track) {
      track.detach().forEach((element) => element.remove());
    },

    handleTrackDisabled(track) {
      if (track.kind === "video") {
        this.remoteVideoEnabled = false;
      }

      if (track.kind === "audio") {
        this.remoteAudioEnabled = false;
      }
    },

    handleTrackEnabled(track) {
      if (track.kind === "video") {
        this.remoteVideoEnabled = true;
      }
      if (track.kind === "audio") {
        this.remoteAudioEnabled = true;
      }
    },

    changeAudio() {
      this.activeRoom.localParticipant.audioTracks.forEach((publication) => {
        if (this.audioOn) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }
      });
      this.audioOn = !this.audioOn;
    },

    changeVideo() {
      this.activeRoom.localParticipant.videoTracks.forEach((publication) => {
        if (this.videoOn) {
          publication.track.disable();
        } else {
          publication.track.enable();
        }
      });
      this.videoOn = !this.videoOn;
    },

    async leaveCall() {
      if (this.localVideoTracks) {
        await this.localVideoTracks.stop();
      }
      if (this.activeRoom) {
        await this.activeRoom.disconnect();
      }
      await this.$router.push({ name: "Sessions" });
    }
  },

  async mounted() {
    await this.$store.dispatch("sessions/getTwilioVideoRoom", this.$route.query.videoRoomId);

    this.audioOn = this.$route.query.isAudioOn;
    this.videoOn = this.$route.query.isVideoOn;
    await this.createTwilioVideo();
    window.addEventListener("beforeunload", async () => {
      if (this.localVideoTracks) {
        this.localVideoTracks.stop();
      }
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }
    });
  },

  async beforeDestroy() {
    if (this.localVideoTracks) {
      this.localVideoTracks.stop();
    }
    if (this.activeRoom) {
      this.activeRoom.disconnect();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.localVideoTracks) {
      this.localVideoTracks.stop();
    }
    if (this.activeRoom) {
      this.activeRoom.disconnect();
    }
    return next();
  }
};
</script>

<style lang="scss" scoped>
#localTrack {
  @apply absolute;
  min-width: 220px;
  max-width: 250px;
  min-height: 140px;
  right: 2rem;
  top: 6rem;
  border-radius: 10px;
  overflow: hidden;
}

#localTrack:hover {
  opacity: 1;
}

#localTrack video {
  border-radius: 6px;
}

#remoteTrack {
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
}

.video-controls {
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  backdrop-filter: blur(40px);
  user-select: none;
}

.h-f {
  height: calc(100vh - 64px);
}

.audio-disabled {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6rem;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(40px);
}
</style>
