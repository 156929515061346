<template>
  <p class="font-bold text-base">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "BodyBold"
};
</script>

<style scoped></style>
