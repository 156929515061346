export const namespaced = true;

export const state = {
  locale: "en-US"
};
export const mutations = {
  SET_LOCALE(state, lang) {
    state.locale = lang;
  }
};
export const actions = {
  setLocale({ commit }, lang) {
    commit("SET_LOCALE", lang);
  }
};
