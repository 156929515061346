<template>
  <div>
    <div :class="{ 'input-error': errors.length > 0 }" class="input-wrapper relative">
      <slot></slot>
      <span v-if="password" class="absolute eye-position cursor-pointer" @click="changeStatus">
        <img v-if="!isOpen" alt="eye-open" src="../../../assets/images/eye-open.svg" />
        <img v-else alt="eye-closed" src="../../../assets/images/eye-closed.svg" />
      </span>
    </div>
    <p v-if="errors.length > 0" class="text-error text-13 ml-6 mt-1">{{ errors[0] }}</p>
  </div>
</template>

<script>
export default {
  name: "MainInput",
  props: {
    errors: {
      default() {
        return [];
      }
    },
    password: Boolean
  },

  data() {
    return {
      isOpen: false
    };
  },

  methods: {
    changeStatus() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$emit("change-to-text");
      } else {
        this.$emit("change-to-password");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  input {
    @apply w-full px-6 py-3 text-primaryText border border-inactiveText transition-all duration-300;
    border-radius: 20px;
    outline: none;
  }

  input::placeholder {
    @apply text-inactiveText;
  }

  input:focus {
    @apply border-primaryBackground;
  }
}

.input-error.input-wrapper {
  input {
    @apply border-error;
  }
}

.eye-position {
  right: 1rem;
  //top: 15px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
