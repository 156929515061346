import Vue from "vue";
import VueI18n from "vue-i18n";
import storageClient from "@/services/storageClient";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

let currentLocale = storageClient.getLanguage();

export default new VueI18n({
  locale:
    currentLocale ||
    window.navigator.language === "de-DE" ||
    window.navigator.language === "en-US" ||
    window.navigator.language === "fr-FR" ||
    window.navigator.language === "it-IT" ||
    "en-US",
  messages: loadLocaleMessages()
});
