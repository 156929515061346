<template>
  <div>
    <div class="avatar bg-placeholder overflow-hidden" @click="$refs.avatar.click()">
      <img
        v-if="!user.avatar"
        alt="empty photo"
        class="text-neutralBackground2 object-cover"
        src="../../assets/images/placeholder.png"
      />
      <img
        v-else
        :src="user.avatar"
        alt="avatar"
        class="text-neutralBackground2 h-full w-full object-cover rounded-full"
      />
    </div>
    <input
      id="avatar"
      ref="avatar"
      accept="image/png, image/jpeg"
      class="hidden"
      name="avatar"
      type="file"
      @input="selectAvatar"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Avatar",
  computed: {
    ...mapState("auth", ["user"])
  },

  methods: {
    async selectAvatar(e) {
      const photo = new FormData();
      photo.append("avatar", e.target.files[0]);
      await this.$store.dispatch("auth/updateProfile", photo);
      this.$refs.avatar.value = "";
    }
  }
};
</script>

<style scoped>
.avatar {
  @apply cursor-pointer rounded-full border-4 border-primaryBackground h-32 w-32 bg-neutralBackground3 mr-12 flex justify-center items-center;
}

.bg-placeholder {
  background: #e4e3e1;
}
</style>
