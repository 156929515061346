<!--440-->
<template>
  <div class="pb-16">
    <div class="w-full grid grid-cols-7 md:grid-cols-4 xs:grid-cols-3 xs:px-4 mt-12">
      <div class="profile-wrapper col-span-5 col-start-2 xs:col-span-3 xs:col-start-1 md:col-span-2 md:col-start-2">
        <div class="flex justify-center items-center flex-col relative">
          <div class="flex justify-start items-start w-full">
            <div>
              <Avatar />
            </div>

            <div class="w-auto">
              <title-text class="text-2xl flex justify-start items-baseline">
                <edit-icon v-if="!nameEdit" @click="nameEdit = true" />
                <span v-if="!nameEdit">{{ user.name }}</span>
                <hidden-input
                  v-else
                  :active="nameEdit"
                  :errors="nameErrors"
                  class="text-sm"
                  textarea
                  @cancel="
                    name = user.name;
                    nameEdit = false;
                  "
                  @save="saveName"
                >
                  <textarea v-model="name" type="text" @input="$v.name.$touch()"></textarea>
                </hidden-input>
              </title-text>
              <p class="my-3 flex justify-start items-baseline mb-3">
                <edit-icon v-if="!expertiseEdit" @click="expertiseEdit = true" />
                <title-text class="text-md mr-2">{{ $t("coach.info.1") }}</title-text>
              </p>

              <p v-if="!expertiseEdit">{{ expertise ? expertise : $t("coach.info.1.subtitle") }}</p>
              <hidden-input
                v-else
                :active="expertiseEdit"
                :errors="expertiseErrors"
                textarea
                @cancel="
                  expertise = user.expertise;
                  expertiseEdit = false;
                "
                @save="saveExpertise"
              >
                <textarea v-model="expertise" type="text" @input="$v.expertise.$touch()"></textarea>
              </hidden-input>

              <p class="my-3 flex justify-start items-baseline mb-3">
                <edit-icon v-if="!qualificationEdit" @click="qualificationEdit = true" />

                <title-text class="text-md mr-2 mt-3">{{ $t("coach.info.2") }}</title-text>
              </p>
              <p v-if="!qualificationEdit">
                {{ qualification ? qualification : $t("coach.info.2.subtitle") }}
              </p>
              <hidden-input
                v-else
                :active="qualificationEdit"
                :errors="qualificationErrors"
                textarea
                @cancel="
                  qualification = user.qualification;
                  qualificationEdit = false;
                "
                @save="saveQualification"
              >
                <textarea v-model="qualification" type="text" @input="$v.qualification.$touch()"></textarea>
              </hidden-input>

              <p class="my-3 flex flex-col justify-start items-baseline my-8">
                <span class="flex justify-start items-baseline mb-4">
                  <edit-icon v-if="!aboutEdit" @click="aboutEdit = true" />

                  <title-text class="text-md mr-2">{{ $t("coach.info.3") }}</title-text>
                </span>
                <span v-if="(about.length === 0 || about === '‎') && !aboutEdit" class="text-inactiveText text-sm">
                  {{ $t("coach.info.3.subtitle") }}
                </span>
                <span v-if="!aboutEdit">{{ about }}</span>
                <hidden-input
                  v-else
                  :active="aboutEdit"
                  :errors="aboutErrors"
                  textarea
                  @cancel="
                    about = user.about;
                    aboutEdit = false;
                  "
                  @save="saveAbout"
                >
                  <textarea v-model="about" :placeholder="$t('tell.about')" type="text"></textarea>
                </hidden-input>
              </p>

              <span class="flex justify-start items-baseline mb-4">
                <edit-icon v-if="!languagesEdit" @click="languagesEdit = true" />

                <title-text class="text-md mr-2">{{ $t("coach.info.4") }}</title-text>
              </span>
              <div v-if="user.languages" class="flex justify-start items-center flex-wrap">
                <div v-if="!languagesEdit" class="flex justify-start items-center flex-wrap">
                  <div v-for="l in selectedLanguages" :key="l.id + 'selected'" class="language-2">
                    <img :alt="l.name" :src="l.media" class="w-6 mr-2" />
                    {{ l.name }}
                  </div>
                </div>
                <div v-else>
                  <div class="flex justify-start items-center flex-wrap w-full">
                    <div
                      v-for="l in languages"
                      :key="l.id"
                      :class="selectedLanguagesID.indexOf(l.id) >= 0 ? 'bg-black text-white' : 'text-black'"
                      class="language"
                      @click="choseLanguage(l.id)"
                    >
                      <img :alt="l.name" :src="l.media" class="w-6 mr-2" />
                      {{ l.name }}
                    </div>
                  </div>
                  <p v-if="languagesErrors.length > 0" class="text-error text-13 ml-6 my-1 w-full text-left">
                    {{ languagesErrors[0] }}
                  </p>
                </div>
                <div v-if="languagesEdit" class="w-full mt-4">
                  <primary-button sm @click="saveLanguages">{{ $t("save.generic") }}</primary-button>
                  <secondary-button class="ml-2" sm @click="cancelLanguages">
                    {{ $t("cancel.generic") }}
                  </secondary-button>
                </div>
              </div>

              <p v-else class="mt-4">{{ $t("coach.info.4.subtitle") }}</p>

              <span class="flex justify-start items-baseline my-4">
                <edit-icon v-if="!coachingEdit" @click="coachingEdit = true" />

                <title-text class="text-md mr-2">{{ $t("coach.info.5") }}</title-text>
              </span>
              <div class="flex justify-start items-center flex-wrap">
                <div v-if="!coachingEdit" class="flex justify-start items-center flex-wrap">
                  <div v-if="user.area.length === 0">{{ $t("coach.info.5.subtitle") }}</div>
                  <div v-for="a in selectedCoaching" :key="a.id + 'selected_area'" class="coaching">
                    {{ a.name }}
                  </div>
                </div>
                <div v-else>
                  <div class="flex justify-start items-center flex-wrap w-full">
                    <div
                      v-for="l in coachingAreas"
                      :key="l.id + '_area'"
                      :class="selectedCoachingID.indexOf(l.id) >= 0 ? 'bg-black text-white' : 'text-black'"
                      class="areas"
                      @click="choseCoachingArea(l.id)"
                    >
                      {{ l.name }}
                    </div>
                  </div>
                  <p v-if="coachingErrors.length > 0" class="text-error text-13 ml-6 my-1 w-full text-left">
                    {{ coachingErrors[0] }}
                  </p>
                </div>
                <div v-if="coachingEdit" class="w-full mt-4">
                  <primary-button sm @click="saveCoaching">{{ $t("save.generic") }}</primary-button>
                  <secondary-button class="ml-2" sm @click="cancelCoaching">
                    {{ $t("cancel.generic") }}
                  </secondary-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/ui-kit/buttons/PrimaryButton";
import { mapState } from "vuex";
import TitleText from "@/components/ui-kit/typography/TitleText";
import EditIcon from "@/components/ui-kit/icons/EditIcon";
import HiddenInput from "@/components/ui-kit/form/HiddenInput";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import SecondaryButton from "@/components/ui-kit/buttons/SecondaryButton";
import Avatar from "@/components/profile/Avatar";

const nameValidator = (value) => {
  return value.indexOf(" ") !== -1 && value.length > value.indexOf(" ") + 2;
};

export default {
  name: "Home",
  components: {
    Avatar,
    SecondaryButton,
    HiddenInput,
    EditIcon,
    TitleText,
    PrimaryButton
  },
  data() {
    return {
      expertise: "",
      expertiseEdit: false,
      qualification: "",
      qualificationEdit: false,
      about: "",
      name: "",
      nameEdit: false,
      aboutEdit: false,
      languagesEdit: false,
      coachingEdit: false,
      selectedCoaching: [],
      coachingErrors: [],
      selectedCoachingID: [],
      selectedLanguages: [],
      languagesErrors: [],
      selectedLanguagesID: []
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("common", ["languages", "coachingAreas"]),
    languagesList() {
      return this.user?.languages;
    },

    expertiseErrors() {
      const errors = [];
      if (!this.$v.expertise.$dirty) return errors;
      !this.$v.expertise.required && errors.push(this.$t("expertise.required"));
      !this.$v.expertise.minLength && errors.push(this.$t("expertise.longer", { count: "2" }));
      !this.$v.expertise.maxLength && errors.push(this.$t("expertise.shorter", { count: "200" }));
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push(this.$t("name.required"));
      !this.$v.name.minLength && errors.push(this.$t("name.longer", { count: "2" }));
      !this.$v.name.nameValidator && errors.push(this.$t("name.two.strings"));
      return errors;
    },

    qualificationErrors() {
      const errors = [];
      if (!this.$v.qualification.$dirty) return errors;
      !this.$v.qualification.required && errors.push(this.$t("qualifications.required"));
      !this.$v.qualification.minLength && errors.push(this.$t("qualifications.longer", { count: "2" }));
      !this.$v.qualification.maxLength && errors.push(this.$t("qualifications.shorter", { count: "200" }));
      return errors;
    }

    // aboutErrors() {
    //   const errors = [];
    //   if (!this.$v.about.$dirty) return errors;
    //   !this.$v.about.required && errors.push(this.$t("about.required"));
    //   !this.$v.about.minLength && errors.push(this.$t("about.longer", { count: "2" }));
    //   return errors;
    // }
  },

  watch: {
    selectedLanguages() {
      this.selectedLanguagesID = this.selectedLanguages.map((i) => i.id);
    },

    selectedCoaching() {
      this.selectedCoachingID = this.selectedCoaching.map((i) => i.id);
    }
  },
  methods: {
    async saveExpertise() {
      this.$v.$touch();
      if (this.$v.expertise.$invalid) {
        return;
      }
      await this.$store.dispatch("auth/updateProfile", {
        expertise: this.expertise,
        area: this.selectedCoachingID,
        language: this.selectedLanguagesID
      });
      this.expertiseEdit = false;
    },

    async saveQualification() {
      this.$v.$touch();
      if (this.$v.qualification.$invalid) {
        return;
      }
      await this.$store.dispatch("auth/updateProfile", {
        qualification: this.qualification,
        area: this.selectedCoachingID,
        language: this.selectedLanguagesID
      });
      this.qualificationEdit = false;
    },

    async saveAbout() {
      await this.$store.dispatch("auth/updateProfile", {
        about: this.about || "‎",
        area: this.selectedCoachingID,
        language: this.selectedLanguagesID
      });
      this.aboutEdit = false;
    },

    choseLanguage(item) {
      if (this.selectedLanguagesID.indexOf(item) > -1) {
        this.selectedLanguagesID.splice(this.selectedLanguagesID.indexOf(item), 1);
      } else {
        this.selectedLanguagesID.push(item);
        this.languagesErrors = [];
      }
    },
    choseCoachingArea(item) {
      if (this.selectedCoachingID.indexOf(item) > -1) {
        this.selectedCoachingID.splice(this.selectedCoachingID.indexOf(item), 1);
      } else {
        this.selectedCoachingID.push(item);
        this.coachingErrors = [];
      }
    },

    async saveLanguages() {
      if (this.selectedLanguagesID.length === 0) {
        this.languagesErrors.push(this.$t("choose.language", { count: "1" }));
        return;
      }

      await this.$store.dispatch("auth/updateProfile", {
        area: this.selectedCoachingID,
        language: this.selectedLanguagesID
      });
      this.languagesEdit = false;
      this.selectedLanguages = this.user.languages;
    },
    async saveCoaching() {
      if (this.selectedCoachingID.length === 0) {
        this.coachingErrors.push(this.$t("choose.area", { count: "1" }));
        return;
      }

      await this.$store.dispatch("auth/updateProfile", {
        area: this.selectedCoachingID,
        language: this.selectedLanguagesID
      });
      this.coachingEdit = false;
      this.selectedCoaching = this.user.area;
    },

    async saveName() {
      this.$v.$touch();
      if (this.$v.name.$invalid) {
        return;
      }
      await this.$store.dispatch("auth/updateProfile", {
        name: this.name,
        area: this.selectedCoachingID,
        language: this.selectedLanguagesID
      });
      this.nameEdit = false;
    },

    cancelCoaching() {
      this.selectedCoaching = this.user.area;
      this.coachingEdit = false;
      this.selectedCoachingID = this.selectedCoaching.map((i) => i.id);
    },
    cancelLanguages() {
      this.selectedLanguages = this.user.languages;
      this.languagesEdit = false;
      this.selectedLanguagesID = this.selectedLanguages.map((i) => i.id);
    }
  },

  validations: {
    expertise: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200)
    },
    qualification: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200)
    },
    name: {
      required,
      minLength: minLength(3),
      nameValidator
    }
  },

  async created() {
    this.expertise = this.user.expertise;
    this.about = this.user.about;
    this.selectedLanguages = this.user.languages;
    this.qualification = this.user.qualification;
    this.selectedCoaching = this.user.area;
    this.name = this.user.name;
    await this.$store.dispatch("common/getLanguages");
    await this.$store.dispatch("common/getCoachingAreas");
  }
};
</script>

<style lang="scss" scoped>
.profile-wrapper {
  @apply bg-white shadow-sm px-6 py-8 rounded-xl w-full shadow-sm;
}

.language {
  @apply rounded-full px-5 py-2 border-2 border-black transition-all duration-300 cursor-pointer  mr-2 mb-2 flex items-center justify-center;
}

.language-2 {
  @apply rounded-full px-5 py-2 bg-neutralBackground2 border-2 border-neutralBackground2 flex items-center justify-center mr-2 mb-2;
}

.coaching {
  @apply rounded-full px-5 py-2 bg-neutralBackground2  border-2 border-neutralBackground2 flex items-center justify-center mr-2 mb-2;
}

.areas {
  @apply rounded-full px-5 py-2 border-2 border-black transition-all duration-300 cursor-pointer mr-2 mb-2 flex items-center justify-center;
}
</style>
